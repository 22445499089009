import firebase from "firebase/compat/app";
import moment from 'moment';

const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

export const DATE_FORMATS = {
  DAY_MM_DD: 'YYYY-MM-DD',
  DAY_MM_DD_HH_MM: 'YYYY-MM-DD H:mm',
};

export const formatDate = (date, format = 'YYYY-MM-DD H:mm:ss') => moment(date).format(format);

export const formatDateTimePicker = (date) => moment(date).format().split('+')[0];

const getNearestSunday = (date) => {
  if (!moment(date).isValid()) {
    throw new Error("Invalid Date");
  }

  const newDate = new Date(date);

  return new Date(newDate.setDate(newDate.getDate() - newDate.getDay()));
};

const getNearestMonday = (date) => {
  if (!moment(date).isValid()) {
    throw new Error("Invalid Date");
  }
  const newDate = new Date(date);

  return new Date(newDate.setDate(newDate.getDate() - newDate.getDay() + 1));
};

const getYearMonthDay = (date) => {
  if (!moment(date).isValid()) {
    throw new Error("Invalid Date");
  }
  const actualDay = date.getDate();
  const actualMonth = +date.getMonth() + 1;

  const year = date.getFullYear();
  const month = actualMonth < 10 ? `0${actualMonth}` : `${actualMonth}`;
  const day = actualDay < 10 ? `0${actualDay}` : `${actualDay}`;

  return { year, month, day };
};

const getFormattedStringWeekDayDate = (date) => {
  if (!date) return 'undefined';

  const {
    year: yearForWeek,
    month: monthForWeek,
    day: dayForWeek,
  } = getYearMonthDay(date);
  return `${yearForWeek}-${monthForWeek}-${dayForWeek}`;
}

const getDateByLogtype = (date, filterBy) => {
  if (filterBy === 'week') {
    const {
      year: yearForWeek,
      month: monthForWeek,
      day: dayForWeek,
    } = getYearMonthDay(projectId === "defisansautosolo-17ee7" ? getNearestMonday(date) : getNearestSunday(date));
    return `${yearForWeek}-${monthForWeek}-${dayForWeek}`;
  } else {
    const { year, month } = getYearMonthDay(date);
    return filterBy === 'month' ? `${year}-${month}` : year.toString();
  }
}

const getDaysInMonth = () => moment().daysInMonth();
const getMonthName = (date) => moment(date).format('MMMM');

const getWeekdays = () => moment.weekdaysShort();
const getWeekday = (date) => moment(date).weekday();

const getWeekdayString = (date) => {
  var dated = moment();
  let day = moment(date).weekday();
  return dated.day(day).format("dddd");
}

const getMonthsShort = () => moment.monthsShort();

const getDateFromFirebaseTimeFormat = (time) => {
  return new firebase.firestore.Timestamp(time.seconds || 0, time.nanoseconds || 0).toDate()
}

const getDateYearAgo = () => new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())
const getDateMonthAgo = () => new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate())
const getDateWeekAgo = () => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)

const getDateYearForward = (startDate) => new Date(new Date(startDate).getFullYear() + 1, new Date(startDate).getMonth(), new Date(startDate).getDate())
const getDateMonthForward = (startDate) => new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + 1, new Date(startDate).getDate())
const getDateWeekForward = (startDate) => new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), new Date(startDate).getDate() + 7)

const getNearestStartDateByFilter = (logType) => {

  const month = new Date(moment().startOf('month').format('YYYY-MM-DD'));
  const year = new Date(moment().startOf('year').add(10, 'hours'));

  if (logType === 'week') return projectId === "defisansautosolo-17ee7" ? getNearestMonday(new Date()) : getNearestSunday(new Date());
  if (logType === 'month') return month;
  if (logType === 'year') return year;
  return projectId === "defisansautosolo-17ee7" ? getNearestMonday(new Date()) : getNearestSunday(new Date());
}

const getShortWeekDayNameByDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format('ddd');
}

const getShortMonthNameByDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format('MMM');
}

const getDayInMonthNameByDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").date();
}

const convertDateToFirestoreTimestamp = (date) => firebase.firestore.Timestamp.fromDate(date);

const getEndDateByLogTypeAndStartDate = (logType, startDate) => {
  if (logType === 'week') return getDateWeekForward(startDate);
  if (logType === 'month') return getDateMonthForward(startDate);
  if (logType === 'year') return getDateYearForward(startDate);
  return startDate
}

const getSecondsToPlanedDate = (planned) => (planned - new Date()) / 1000;


const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const dayNameMapping = {
  "Sun": "Sunday",
  "Mon": "Monday",
  "Tue": "Tuesday",
  "Wed": "Wednesday",
  "Thu": "Thursday",
  "Fri": "Friday",
  "Sat": "Saturday"
};
const getCloserScheduleDateString = (inputObject = {}, translation = () => null) => {
  const currentDayAbbreviated = new Date().toLocaleDateString('en-US', { weekday: 'short' });

  let closestDayAbbreviated = currentDayAbbreviated;
  let closestTime = inputObject[currentDayAbbreviated];

  for (let i = 1; i <= 7; i++) {
    const nextDayIndex = (daysOfWeek.indexOf(currentDayAbbreviated) + i) % 7;
    const nextDayAbbreviated = daysOfWeek[nextDayIndex];
    const nextTime = inputObject[nextDayAbbreviated];

    if (nextTime !== undefined) {
      closestDayAbbreviated = nextDayAbbreviated;
      closestTime = nextTime;
      break;
    }
  }

  if (closestTime !== undefined) {
    const closestDayFull = dayNameMapping[closestDayAbbreviated];

    const dayTranslated = translation(`weekDays.${closestDayFull}`)
    const at = translation(`meta.at`)
    const resultString = `${dayTranslated} ${at} ${closestTime}`;
    return resultString;
  } else {
    return;
  }
}

const getAllScheduleDatesArray = (inputObject = {}, translation = () => null) => {
  const schedules = [];

  // Loop through each day in the input object
  for (const dayAbbreviated in inputObject) {
    const time = inputObject[dayAbbreviated];

    // Find the closest day in the future
    let closestDayAbbreviated = dayAbbreviated;
    let closestTime = time;

    for (let i = 1; i <= 7; i++) {
      const nextDayIndex = (daysOfWeek.indexOf(dayAbbreviated) + i) % 7;
      const nextDayAbbreviated = daysOfWeek[nextDayIndex];
      const nextTime = inputObject[nextDayAbbreviated];

      if (nextTime !== undefined) {
        closestDayAbbreviated = nextDayAbbreviated;
        closestTime = nextTime;
        break;
      }
    }

    if (closestTime !== undefined) {
      // Get the full day name from the mapping
      const closestDayFull = dayNameMapping[closestDayAbbreviated];

      // Create the final string with the full day name
      const dayTranslated = translation(`weekDays.${closestDayFull}`)
      const at = translation(`meta.at`)

      const resultString = `${dayTranslated} ${at} ${closestTime}`;
      schedules.push(resultString);
    } else {
      schedules.push(`No valid schedule found for ${dayAbbreviated}.`);
    }
  }
  return schedules;
}

const getScheduleStringByDate = (date, translation = () => null) => {
  const currentDate = date ?? new Date();
  const options = { weekday: 'long' };
  const weekday = currentDate.toLocaleDateString('en-US', options);

  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();

  const dayTranslated = translation(`weekDays.${weekday}`)
  const at = translation(`meta.at`)


  // Format the time as "hours:minutes"
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return `${dayTranslated} ${at} ${formattedTime}`;
}

const getFullYear = () => new Date().getFullYear();

const dateUtils = {
  formatDate,
  getDateByLogtype,
  getNearestSunday,
  getNearestMonday,
  getDaysInMonth,
  getMonthName,
  getWeekdays,
  getWeekday,
  getMonthsShort,
  getDateYearAgo,
  getDateMonthAgo,
  getDateWeekAgo,
  getDateFromFirebaseTimeFormat,
  getDateYearForward,
  getDateMonthForward,
  getDateWeekForward,
  getNearestStartDateByFilter,
  getShortWeekDayNameByDate,
  getShortMonthNameByDate,
  getDayInMonthNameByDate,
  convertDateToFirestoreTimestamp,
  getEndDateByLogTypeAndStartDate,
  getSecondsToPlanedDate,
  getCloserScheduleDateString,
  getAllScheduleDatesArray,
  getScheduleStringByDate,
  getWeekdayString,
  dayNameMapping,
  getFormattedStringWeekDayDate,
  getFullYear,
}

export default dateUtils;