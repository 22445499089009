import axios from "axios";
import { firestore } from "containers/firebase";
import { COLLECTION } from "shared/strings/firebase";
import { firestoreToArray } from "./helpers";
import moment from "moment";
import batchUtils from "utils/batchUtils";
import { config } from "translations";

const { REACT_APP_CLOUD_FUNCTION_API_URL } = process.env;

// common for cities and organisations
export const getCompletelyStats = async ({ ownerType, ownerId, period, periodKey, branchId, withAllUsers, startDate, endDate }) => {
  console.log(`getCompletelyStats`)
  if (period == null) {
    return;
  }

  const localKey = `ownerType=${ownerType}&ownerId=${ownerId}`;
  const localStored = JSON.parse(localStorage.getItem(localKey) ?? '{}');

  if (moment(localStored.updateTimestamp).isSame(moment(), "date")) {
    if (localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`]) {
      console.log("return LocalStorage for", `period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`);
      return localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`];
    }
  } else {
    localStorage.setItem(localKey, JSON.stringify({
      ...localStored,
      refreshed: false,
      data: {}
    }));
  }

  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/v2/get-stats-by?ownerType=${ownerType}&ownerId=${ownerId}&period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error getCompletelyStats : ${err}`) });

  try {
    localStorage.setItem(localKey, JSON.stringify({
      ...localStored,
      updateTimestamp: Date.now(),
      refreshed: localStored.refreshed || false,
      data: {
        ...(localStored.data || {}),
        [`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`]: res,
      }
    }));
  } catch (error) {
    console.log('Maximum local storage size error in get-stats-by: ' + error.message)
  }

  return res;
};

export const getUsersActivities = async ({ ownerType, ownerId, period, periodKey, branchId, withAllUsers, startDate, endDate }) => {
console.log('period', period)
  if (period == null) {
    return;
  }
  console.log(`getUsersActivities`)
  const localKey = `ownerType=${ownerType}&ownerId=${ownerId}&userActivities=${true}`;
  const localStored = JSON.parse(localStorage.getItem(localKey) ?? '{}');

  if (moment(localStored.updateTimestamp).isSame(moment(), "date")) {
    if (localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}&userActivities=${true}`]) {
      return localStored.data?.[`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}&userActivities=${true}`];
    }
  } else {
    localStorage.setItem(localKey, JSON.stringify({
      ...localStored,
      refreshed: false,
      data: {}
    }));
  }

  console.log(`calling get-useractivities-stats-by?ownerType=${ownerType}`)
  const res = await axios
    .get(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/statsApi/v2/get-useractivities-stats-by?ownerType=${ownerType}&ownerId=${ownerId}&period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data)
    .catch((err) => { console.log(`error get-useractivities-stats-by : ${err}`) });

  try {
    localStorage.setItem(localKey, JSON.stringify({
      ...localStored,
      updateTimestamp: Date.now(),
      refreshed: localStored.refreshed || false,
      data: {
        ...(localStored.data || {}),
        [`period=${period}&periodKey=${periodKey}&branchId=${branchId}&withAllUsers=${withAllUsers}&startDate=${startDate}&endDate=${endDate}&userActivities=${true}`]: res,
      }
    }));
  } catch (error) {
    console.log('Maximum local storage size error in get-useractivities-stats-by: ' + error.message)
  }

  return res;

};

export const getCityAllOrganisations = (cityId) => {
  return firestore.collection(COLLECTION.Organisations)
    .where('cityId', '==', cityId)
    .get()
    .then(firestoreToArray);
};

export const onceUpdateAllDocumentsInCollection = async (collectionName, data) => {
  if (!collectionName || !data) {
    throw new Error(`Invalid collectionName: ${collectionName} or data: ${data}`);
  }
  await firestore
    .collection(collectionName)
    .get()
    .then(async (snapshot) => {
      const docsWitId = firestoreToArray(snapshot);;
      return batchUtils.batchLimitParallel({
        firestore: firestore,
        items: docsWitId,
        limit: 250,
        onEach: async (doc, batch) => {
          const docRef = firestore.collection(collectionName).doc(doc.id);
          batch.update(docRef, data);
        },
      });
    });
};